import { graphql } from "gatsby";
import * as React from "react";
import { parseStory } from "../components/utils";
import Layout from "../components/layout/layout";
import { useState } from "react";
import ch from "../resources/assets/done.svg";
import notch from "../resources/assets/close-r.svg";
import arrL from "../resources/assets/arr-left.svg";
import { useRef } from "react";

// markup
const Publications = ({ data }) => {
  const allPubs = parseStory(data.pubs).content.pubs.reverse();
  const groupedPubs = allPubs.reduce((obj, p) => {
    const y = p.year;
    y in obj ? obj[y].push(p) : (obj[y] = [p]);
    return obj;
  }, {});
  const [filters, setFilters] = useState(
    allPubs.reduce((obj, p) => {
      p.tags.forEach((t) => {
        obj[t.text] = true;
      });
      return obj;
    }, {})
  );

  const [showF, setShowF] = useState(false);
  const filtersRef = useRef();

  return (
    <Layout>
      <div className="relative">
        <div
          onClick={() => {
            setShowF(false);
          }}
          className={`absolute w-full h-full z-10 bg-black ${
            showF ? "opacity-60" : "opacity-0 pointer-events-none"
          }`}
          style={{ minHeight: "calc(100vh - 96px)" }}
        />
        <div className="py-6 md:py-12 as-cont">
          <h1 className="as-h1 flex justify-between items-center">
            Publications
            <button
              className="md:hidden pr-2 py-1 bg-slate-200 border border-gray-900 rounded-lg cursor-pointer flex items-center text-sm"
              onClick={() => {
                setShowF(true);
              }}
            >
              <img src={arrL} height={16} alt="open" />
              Filters
            </button>
          </h1>
          <div
            className="relative overflow-hidden"
            style={{
              minHeight: (filtersRef?.current?.clientHeight ?? 200) + "px",
            }}
          >
            <div
              className={`rounded-md z-10 flex gap-4 flex-col absolute md:top-2 md:right-4 top-0 right-0 md:w-auto bg-white md:bg-transparent p-4 shadow-md md:shadow-none transition-all ${
                showF ? "translate-x-0" : "translate-x-full"
              } md:translate-x-0`}
              ref={filtersRef}
            >
              <h3 className="font-bold text-xl text-right">Filter by topic:</h3>
              <div className="flex flex-col gap-4 items-end">
                {Object.entries(filters).map(([lab, checked]) => (
                  <label
                    key={lab}
                    className="flex gap-1 bg-slate-200 border border-gray-900 px-2 py-1 text-sm rounded-lg items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      className="sr-only"
                      checked={checked}
                      onChange={(e) => {
                        setFilters((old) => ({
                          ...old,
                          [lab]: e.target.checked,
                        }));
                      }}
                    />
                    {lab}
                    <img src={checked ? ch : notch} alt="" height={20} />
                  </label>
                ))}
              </div>
              <div className="md:hidden flex justify-end">
                <button
                  className="cursor-pointer underline"
                  onClick={() => {
                    setShowF(false);
                  }}
                >
                  close filters
                </button>
              </div>
            </div>
            <ul className="gap-4 flex flex-col mt-8 -z-10 md:w-3/4">
              {Object.entries(groupedPubs)
                .filter(([y, _]) => !isNaN(parseInt(y)))
                .sort((a, b) => parseInt(b[0]) - parseInt(a[0]))
                .map(([y, pubs]) => (
                  <PubsByYear
                    y={y}
                    pubs={pubs}
                    key={`year-${y}`}
                    filters={filters}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const PubsByYear = ({ y, pubs, filters }) => {
  const filteredPubs = pubs.filter((p) => p.tags.some((t) => filters[t.text]));

  /* if (filteredPubs.length === 0) return null; */
  return (
    <>
      <div className="font-bold text-2xl pb-2 border-b mt-10 first-of-type:mt-0 w-2/3">
        {y}
      </div>
      {filteredPubs.length === 0 ? (
        <p>No publications matching your filters for year {y}</p>
      ) : (
        filteredPubs.map((p) => (
          <li key={p["_uid"]}>
            <h2 className="font-bold underline">
              <a href={p.link} target="_blank" rel="noopener noreferrer">
                {p.title}
              </a>
            </h2>
            <p className="mt-0.5">{p.authors}</p>
            <p className="mt-0.5 text-sky-700">{p.journal}</p>
            <div className="mt-2 flex gap-1 flex-wrap">
              {p.tags.map((t) => (
                <p
                  key={t["_uid"]}
                  className="px-2 py-1 rounded-lg bg-slate-200 text-sm shadow-sm"
                >
                  {t.text}
                </p>
              ))}
            </div>
          </li>
        ))
      )}
    </>
  );
};

export const query = graphql`
  query {
    pubs: storyblokEntry(slug: { eq: "publications" }) {
      content
    }
  }
`;

export default Publications;
