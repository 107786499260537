import React from "react";

const ImagesBlok = ({ data }) => {
  return (
    <div className="overflow-hidden py-2 sm:py-4">
      <div className="flex sm:grid sm:grid-cols-2 md:grid-cols-3 gap-4 snap-x sm:snap-none snap-mandatory overflow-x-auto hide-bar">
        {(data ?? []).map((img) => (
          <div className="flex-shrink-0 snap-start w-10/12 sm:w-auto">
            <img
              src={img.filename}
              alt={img?.alt ?? ""}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImagesBlok;
