import ImagesBlok from "./commons/images-blok";
import RichtextBlok from "./commons/richtext-blok";
import React from "react";

export const parseStory = (story) => {
  let content = story?.content;
  if ("content" in story && typeof content === "string") {
    content = JSON.parse(story.content);
  }
  return {
    ...story,
    content,
  };
};

export const getBlock = (b) => {
  switch (b.component) {
    case "text_block":
      return <RichtextBlok data={b.text} key={b["_uid"]} />;
    case "images_block":
      return <ImagesBlok data={b.imgs} key={b["_uid"]} />;
    default:
      return null;
  }
};
